var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "conta-bancaria" },
    _vm._l(_vm.listaContasBancariasTomador, function (conta, index) {
      return _c(
        "div",
        { key: conta.UniqueId, staticClass: "conta-bancaria-component" },
        [
          _c(
            "div",
            { staticClass: "conta-bancaria-component-select" },
            [
              _c("RadioButton", {
                staticClass: "conta-bancaria-component-select-radio",
                attrs: { id: conta.Id, name: conta.Id, value: conta },
                model: {
                  value: _vm.selectedCategory,
                  callback: function ($$v) {
                    _vm.selectedCategory = $$v
                  },
                  expression: "selectedCategory",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "conta-bancaria-component-conteudo" }, [
            _c(
              "div",
              { staticClass: "conta-bancaria-component-conteudo-titulo" },
              [
                _c("p", [
                  _vm._v(
                    _vm._s(conta.Bank.Number) + " - " + _vm._s(conta.Bank.Name)
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "conta-bancaria-component-conteudo-texto" },
              [
                _c("p", [
                  _vm._v(
                    "\n          Agência " +
                      _vm._s(conta.Branch) +
                      " " +
                      _vm._s(conta.BankAccountType.Name) +
                      "\n          " +
                      _vm._s(conta.Number) +
                      " (" +
                      _vm._s(conta.PaymentMethod.Name) +
                      ")\n        "
                  ),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "conta-bancaria-component-remover" },
            [
              _c(
                "Button",
                {
                  staticClass: "conta-bancaria-component-remover-botao",
                  on: {
                    click: function ($event) {
                      return _vm.removerConta()
                    },
                  },
                },
                [_c("i", { staticClass: "onpoint-trash" })]
              ),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }