<template>
  <Dialog :visible="visible" :closable="false" class="adicionar-nova-conta">
    <template #header>
      <div class="adicionar-nova-conta-header">
        <p class="adicionar-nova-conta-header-title">Dados bancários</p>
      </div>
    </template>
    <Divider />
    <div class="adicionar-nova-conta-body">
      <div class="adicionar-nova-conta-body-primeiro">
        <Button
          class="adicionar-nova-conta-body-primeiro-principal"
          label="Conta principal"
          @click="console.log('teste')"
        />
        <Button
          class="adicionar-nova-conta-body-primeiro-adicionar"
          icon="pi pi-plus-circle"
          label="Adicionar nova conta"
          @click="console.log('teste')"
        />
      </div>
      <div class="adicionar-nova-conta-body-segundo">
        <OnpointSelect
          label="Selecione o banco"
          placeholder="Selecione"
          class="w-5/12 adicionar-nova-conta-body-segundo-banco"
          :required="true"
          :items="testes"
          v-model="teste"
        ></OnpointSelect>
        <OnpointSelect
          label="Selecione o tipo de conta"
          placeholder="Selecione"
          class="w-7/12 adicionar-nova-conta-body-segundo-tipo-conta"
          :required="true"
          :items="testes"
          v-model="teste"
        ></OnpointSelect>
      </div>
      <div class="adicionar-nova-conta-body-terceiro">
        <div class="w-5/12 flex" style="margin: 0 24px 0 0;">
          <OnpointInput
            label="Agência"
            class="w-8/12 adicionar-nova-conta-body-terceiro-agencia"
            :placeholderValue="'Agência'"
            required
            v-model="teste"
            @input="console.log('teste')"
          />
          <OnpointInput
            label="Digito"
            class="w-4/12 adicionar-nova-conta-body-terceiro-agencia-digito"
            :placeholderValue="'Digito'"
            required
            v-model="teste"
            @input="console.log('teste')"
          />
        </div>
        <div class="w-7/12 flex">
          <OnpointInput
            label="Conta"
            class="w-9/12 adicionar-nova-conta-body-terceiro-conta"
            :placeholderValue="'Conta'"
            required
            v-model="teste"
            @input="console.log('teste')"
          />
          <OnpointInput
            label="Digito"
            class="w-3/12 adicionar-nova-conta-body-terceiro-conta-digito"
            :placeholderValue="'Digito'"
            required
            v-model="teste"
            @input="console.log('teste')"
          />
        </div>
      </div>
      <div class="adicionar-nova-conta-body-quarto">
        <div class="w-5/12" style="margin: 0 24px 0 0;">
          <OnpointSelect
            label="Selecione o meio de pagamento"
            placeholder="Selecione"
            class="adicionar-nova-conta-body-quarto-pagamento"
            :required="true"
            :items="testes"
            v-model="teste"
          ></OnpointSelect>
        </div>
        <div class="w-7/12"></div>
      </div>
      <div class="adicionar-nova-conta-body-quinto">
        <Button
          class="adicionar-nova-conta-body-quinto-deletar"
          label="Deletar conta"
          @click="console.log('teste')"
        />
      </div>
    </div>
    <Divider />
    <template #footer>
      <div class="adicionar-nova-conta-footer">
        <Button
          class="adicionar-nova-conta-footer-cancelar"
          label="Cancelar"
          @click="$emit('fecharModal')"
        />
        <Button
          class="adicionar-nova-conta-footer-salvar"
          label="Salvar"
          @click="$emit('salvarNovaConta')"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Button from "primevue/button";
import OnpointInput from "@/components/onpoint-input";
import OnpointSelect from "@/components/onpoint-select/index.vue";

export default {
  name: "adicionar-nova-conta-dialog",
  props: {
    visible: Boolean,
  },
  components: {
    Dialog,
    Divider,
    Button,
    OnpointInput,
    OnpointSelect,
  },
  data() {
    return {
      itemSelected: null,
      teste: null,
      testes: [
        { Name: "teste1", Id: 1 },
        { Name: "teste2", Id: 2 },
        { Name: "teste3", Id: 3 },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.adicionar-nova-conta {
  :deep(.p-dialog) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    color: rgba(75, 85, 99, 1);
    padding: 0;
    border-radius: 20px;
    width: 50%;
    min-width: 873px;
  }

  :deep(.p-dialog-header),
  :deep(.p-dialog-content),
  :deep(.p-dialog-footer) {
    padding: 0;
    overflow-y: hidden;
  }

  :deep(.p-dialog-header) {
    border-radius: 20px 20px 0 0;
  }

  :deep(.p-dialog-footer) {
    border-radius: 0 0 20px 20px;
  }

  :deep(.p-divider.p-divider-horizontal) {
    margin: 1px 0;
  }

  :deep(.p-divider.p-divider-horizontal:before) {
    border-top: 1px solid rgba(223, 234, 241, 1);
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;

    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      color: rgba(75, 85, 99, 1);
    }
  }

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 32px 84px 42px;

    &-primeiro,
    &-segundo,
    &-terceiro,
    &-quarto,
    &-quinto {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-segundo,
    &-terceiro,
    &-quarto,
    &-quinto {
      margin-top: 24px;
    }

    &-primeiro {
      &-principal {
        margin: 0 8px 0 0;
      }

      &-adicionar {
        margin: 0;
        background: rgba(246, 246, 246, 1);
        border: 2px dashed rgba(203, 207, 210, 1);
        color: rgba(75, 85, 99, 1);
      }
    }

    &-segundo {
      &-banco {
        margin: 0 24px 0 0;
      }

      &-tipo-conta {
        margin: 0;
      }
    }

    &-terceiro {
      &-agencia, 
      &-conta {
        margin: 0 24px 0 0;
      }
    }

    &-quarto {
      &-pagamento {
        margin: 0 24px 0 0;
      }
    }

    &-quinto {
      &-deletar {
        background: none;
        border: none;
        color: rgba(206, 12, 70, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 8px !important;

        &:hover {
          background: rgba(206, 12, 70, 0.1);
          color: rgba(206, 12, 70, 1);
        }
      }
    }
  }

  &-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 56px;

    &-cancelar,
    &-salvar {
      padding: 10px 40px !important;
      border-radius: 8px !important;
    }

    &-cancelar {
      margin: 0 24px 0 0 !important;
      border: 1px solid rgba(209, 213, 219, 1) !important;
      background-color: rgba(255, 255, 255, 1) !important;
      color: rgba(31, 41, 55, 1) !important;

      &:hover {
        background-color: rgba(209, 213, 219, 0.8) !important;
      }
    }

    &-salvar {
      margin: 0 !important;
    }
  }
}
</style>
