<template>
  <div class="dados-bancarios-tomador">
    <h3 class="dados-bancarios-tomador-titulo">Dados bancários do Tomador</h3>
    <div class="dados-bancarios-tomador-lista">
      <p>Selecione uma conta para recebimento <span>*</span></p>
      <ContaBancariaComponent
        :listaContasBancariasTomador="listaContasBancariasTomador"
      />
    </div>
    <div class="dados-bancarios-tomador-adicao">
      <Button
        label="Adicionar nova conta"
        class="p-button-rounded dados-bancarios-tomador-adicao-botao"
        @click="exibirModalAdicionarNovaConta()"
      />
    </div>
    <AdicionarNovaContaDialog
      :visible="adicionarNovaContaDialog"
      @fecharModal="adicionarNovaContaDialog = false"
      @salvarNovaConta="adicionarContaBancaria()"
    />
  </div>
</template>

<script>
import OnpointInput from "@/components/onpoint-input";
import OnpointSelect from "@/components/onpoint-select/index.vue";
import Button from "primevue/button";
import ContaBancariaComponent from "./ContaBancariaComponent.vue";
import AdicionarNovaContaDialog from "./AdicionarNovaContaDialog.vue";

export default {
  name: "dados-bancarios-do-tomador",
  components: {
    OnpointSelect,
    OnpointInput,
    Button,
    ContaBancariaComponent,
    AdicionarNovaContaDialog,
  },
  props: {
    listaContasBancariasTomador: {
      type: Array,
      default: () => [],
    },
    listaBancos: {
      type: Array,
      default: () => [],
    },
    tiposContasBancarias: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      teste: null,
      adicionarNovaContaDialog: false,
    };
  },
  methods: {
    exibirModalAdicionarNovaConta() {
      this.adicionarNovaContaDialog = true;
    },

    adicionarContaBancaria() {
      console.log("salavando dados");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.dados-bancarios-tomador {
  color: rgba(55, 65, 81, 1);

  &-titulo {
    padding-top: 12px;
  }
  
  &-lista {
    width: 100%;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &-adicao {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: rgba(249, 250, 251, 1);

    &-botao {
      background: white;
      color: black;
      border: solid 1px rgba(209, 213, 219, 1);
    }
  }
}
</style>
