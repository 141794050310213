import { render, staticRenderFns } from "./AdicionarNovaContaDialog.vue?vue&type=template&id=bb4effde&scoped=true"
import script from "./AdicionarNovaContaDialog.vue?vue&type=script&lang=js"
export * from "./AdicionarNovaContaDialog.vue?vue&type=script&lang=js"
import style0 from "./AdicionarNovaContaDialog.vue?vue&type=style&index=0&id=bb4effde&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb4effde",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb4effde')) {
      api.createRecord('bb4effde', component.options)
    } else {
      api.reload('bb4effde', component.options)
    }
    module.hot.accept("./AdicionarNovaContaDialog.vue?vue&type=template&id=bb4effde&scoped=true", function () {
      api.rerender('bb4effde', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/endossos-new/criacao/informacoes-endosso/cancelamento/components/AdicionarNovaContaDialog.vue"
export default component.exports