var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Card", {
        staticClass: "card",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "card-content-data-cancelamento" },
                  [
                    _c(
                      "h3",
                      { staticClass: "card-content-data-cancelamento-title" },
                      [_vm._v("Endosso")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "card-content-data-cancelamento-subtitle",
                      },
                      [
                        _vm._v(
                          "\n          Informações para cancelamento\n        "
                        ),
                      ]
                    ),
                    _c("OnpointInput", {
                      staticClass: "w-1/4",
                      attrs: {
                        label: "Data de cancelamento",
                        required: true,
                        calendar: true,
                        minDateValue: _vm.dataMinima,
                        maxDateValue: _vm.dataMaxima,
                      },
                      on: { input: _vm.calcularPremioCancelamento },
                      model: {
                        value: _vm.dataCancelamento,
                        callback: function ($$v) {
                          _vm.dataCancelamento = $$v
                        },
                        expression: "dataCancelamento",
                      },
                    }),
                  ],
                  1
                ),
                _vm.exibirDadosBancariosTomador
                  ? _c("Divider", { staticClass: "card-content-divisor" })
                  : _vm._e(),
                _vm.exibirDadosBancariosTomador
                  ? _c(
                      "div",
                      { staticClass: "card-content-dados-bancarios-tomador" },
                      [
                        _c("DadosBancariosTomador", {
                          attrs: {
                            listaContasBancariasTomador:
                              _vm.listaContasBancariasTomador,
                            listaBancos: _vm.listaBancos,
                            tiposContasBancarias: _vm.tiposContasBancarias,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "flex justify-end card-footer" },
                  [
                    _c("Button", {
                      staticClass: "p-button-rounded cancel-button",
                      attrs: { label: "Cancelar" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push({ path: "/" })
                        },
                      },
                    }),
                    _c("Button", {
                      staticClass: "p-button-rounded",
                      attrs: { label: "Próximo" },
                      on: {
                        click: function ($event) {
                          return _vm.nextPage()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.exibirPremio ? _c("PremioComponent") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }