<template>
  <div class="conta-bancaria">
    <div
      class="conta-bancaria-component"
      v-for="(conta, index) in listaContasBancariasTomador"
      :key="conta.UniqueId"
    >
      <div class="conta-bancaria-component-select">
        <RadioButton
          class="conta-bancaria-component-select-radio"
          :id="conta.Id"
          :name="conta.Id"
          :value="conta"
          v-model="selectedCategory"
        />
      </div>
      <div class="conta-bancaria-component-conteudo">
        <div class="conta-bancaria-component-conteudo-titulo">
          <p>{{ conta.Bank.Number }} - {{ conta.Bank.Name }}</p>
        </div>
        <div class="conta-bancaria-component-conteudo-texto">
          <p>
            Agência {{ conta.Branch }} {{ conta.BankAccountType.Name }}
            {{ conta.Number }} ({{ conta.PaymentMethod.Name }})
          </p>
        </div>
      </div>
      <div class="conta-bancaria-component-remover">
        <Button
          class="conta-bancaria-component-remover-botao"
          @click="removerConta()"
          ><i class="onpoint-trash"></i
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import RadioButton from "primevue/radiobutton";

export default {
  name: "conta-bancaria-component",
  props: {
    listaContasBancariasTomador: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Button,
    RadioButton,
  },
  data() {
    return {
      titulo: "teste",
      texto: "teste",
      selectedCategory: null,
    };
  },
  methods: {
    removerConta() {
      console.log("removerConta");
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.conta-bancaria {
  width: 100%;

  &-component {
    display: flex;
    flex-direction: row;
    margin: 12px 0;

    &-select {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 24px 0 0;
      padding: 12px;
      background-color: rgba(249, 250, 251, 1);
      border-radius: 8px;

      &-radio {
        :deep(.p-radiobutton-box) {
          padding: 4px;
          border: 2px solid rgba(209, 213, 219, 1) !important;
        }
        
        :deep(.p-radiobutton-icon){
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #ffffff;
        }

        :deep(.p-highlight) {
          background: rgba(var(--vs-primary), 1) !important;
        }
      }
    }

    &-conteudo {
      width: 100%;
      margin: 0 24px 0 0;
      padding: 12px 24px;
      border-radius: 8px;
      background-color: rgba(249, 250, 251, 1);

      &-titulo {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
        color: rgba(55, 65, 81, 1);
      }

      &-texto {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(17, 24, 39, 1);
      }
    }

    &-remover {
      display: flex;
      justify-content: center;
      align-items: center;

      &-botao {
        width: 100%;
        height: 100%;
        color: black;
        border-radius: 8px !important;
        background: rgba(251, 178, 191, 1);

        i:before {
          font-size: 24px;
        }
      }

      &-botao:hover {
        background: rgba(251, 178, 191, 0.8);
      }
    }
  }
}
</style>
