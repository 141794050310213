<template>
  <section>
    <Card class="card">
      <template #content>
        <div class="card-content-data-cancelamento">
          <h3 class="card-content-data-cancelamento-title">Endosso</h3>
          <p class="card-content-data-cancelamento-subtitle">
            Informações para cancelamento
          </p>
          <OnpointInput
            class="w-1/4"
            label="Data de cancelamento"
            v-model="dataCancelamento"
            :required="true"
            :calendar="true"
            :minDateValue="dataMinima"
            :maxDateValue="dataMaxima"
            @input="calcularPremioCancelamento"
          />
        </div>
        <Divider
          v-if="exibirDadosBancariosTomador"
          class="card-content-divisor"
        />
        <div
          v-if="exibirDadosBancariosTomador"
          class="card-content-dados-bancarios-tomador"
        >
          <DadosBancariosTomador
            :listaContasBancariasTomador="listaContasBancariasTomador"
            :listaBancos="listaBancos"
            :tiposContasBancarias="tiposContasBancarias"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end card-footer">
          <Button
            label="Cancelar"
            class="p-button-rounded cancel-button"
            @click="$router.push({ path: '/' })"
          />
          <Button
            label="Próximo"
            class="p-button-rounded"
            @click="nextPage()"
          />
        </div>
      </template>
    </Card>
    <PremioComponent v-if="exibirPremio"/>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import Divider from "primevue/divider";
import OnpointInput from "@/components/onpoint-input";
import DadosBancariosTomador from "./components/DadosBancariosTomador.vue";
import PremioComponent from "@/components/endosso/premio/PremioComponent.vue";

import endossoProvider from "@/providers/endosso-provider.js";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "cancelamento-informacoes-do-endosso",
  components: {
    Card,
    Button,
    Divider,
    OnpointInput,
    DadosBancariosTomador,
    PremioComponent
  },
  data() {
    return {
      listaBancos: [],
      tiposContasBancarias: [],
      listaContasBancariasTomador: [],
      exibirDadosBancariosTomador: false,
      exibirPremio: false
    };
  },
  computed: {
    ...mapGetters("endosso-module-new", [
      "apoliceOriginalEndosso",
      "endosso",
      "parametrosEndossoCancelamento",
    ]),

    dataMinima() {
      const dataMinima = this.parametrosEndossoCancelamento.DataMinima;
      return dataMinima ? new Date(dataMinima) : null;
    },

    dataMaxima() {
      const dataMaxima = this.parametrosEndossoCancelamento.DataMaxima;
      return dataMaxima ? new Date(dataMaxima) : null;
    },

    dataCancelamento: {
      get() {
        return this.endosso.DataCancelamento;
      },
      set(value) {
        this.updateDataCancelamento(value);
      },
    },
  },
  methods: {
    ...mapMutations("endosso-module-new", [
      "updateDataCancelamento",
      "updateInsurancePremium",
      "updateInsurancePremiumPrincipalModality",
      "updateInsurancePremiumComplementarModality",
    ]),

    nextPage() {
      this.$emit("nextPage", { formData: {}, pageIndex: 0 });
    },

    async buscarDadosInicias() {
      try {
        const [bancos, tiposContasBancarias, contasBancariasTomador] =
          await Promise.all([
            endossoProvider.obterBancos(),
            endossoProvider.obterTiposContasBancarias(),
            endossoProvider.obterContasBancariasTomador(
              this.apoliceOriginalEndosso.PolicyHolderUniqueId
            ),
          ]);

        this.listaBancos = bancos;
        this.tiposContasBancarias = tiposContasBancarias;
        this.listaContasBancariasTomador = contasBancariasTomador;
      } catch (errors) {
        const errorMessage = errors.response
          ? errors.response.data.Errors
          : "Erro ao buscar dados do endosso de cancelamento";
        this.$onpoint.errorModal(errorMessage);
      }
    },

    async calcularPremioCancelamento() {
      await this.$onpoint.loading(async () => {
        try {
          let payload = {
            PolicyUniqueiId: this.$route.params.policyUniqueId,
            SubTipoEndossoId: this.$route.params.subTipoEndossoId,
            DataCancelamento: this.dataCancelamento,
          };

          let premio = await endossoProvider.calcularPremioCancelamento(payload);

          this.updateInsurancePremium(premio.InsurancePremium);
          this.updateInsurancePremiumPrincipalModality(premio.InsurancePremiumPrincipalModality);
          this.updateInsurancePremiumComplementarModality(premio.InsurancePremiumComplementarModality);

          if (premio.InsurancePremium < 0) {
            this.exibirDadosBancariosTomador = true;
            this.exibirPremio = true;
          }
        } catch (error) {         
          this.$onpoint.errorModal(error);
        }
      });
    },
  },
  created() {
    this.buscarDadosInicias();
  },
};
</script>

<style lang="scss" scoped>
.card {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  color: rgba(75, 85, 99, 1);
  padding: 24px;
  border-radius: 16px;
  width: 100%;

  :deep(.p-card-body),
  :deep(.p-card-content),
  :deep(.p-card-footer) {
    padding: 0;
  }

  :deep(.p-divider.p-divider-horizontal:before) {
    border-top: 1px solid rgba(223, 234, 241, 1) !important;
  }

  &-content {
    &-data-cancelamento {
      padding-top: 12px;

      &-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: rgba(55, 65, 81, 1);
      }

      &-subtitle {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(75, 85, 99, 1);
        margin-bottom: 36px;
      }
    }

    &-divisor {
      margin: 40px 0;
      padding: 0;
    }
  }

  &-footer {
    margin-top: 64px;
  }
}

.cancel-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
}
</style>
