var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dados-bancarios-tomador" },
    [
      _c("h3", { staticClass: "dados-bancarios-tomador-titulo" }, [
        _vm._v("Dados bancários do Tomador"),
      ]),
      _c(
        "div",
        { staticClass: "dados-bancarios-tomador-lista" },
        [
          _vm._m(0),
          _c("ContaBancariaComponent", {
            attrs: {
              listaContasBancariasTomador: _vm.listaContasBancariasTomador,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dados-bancarios-tomador-adicao" },
        [
          _c("Button", {
            staticClass:
              "p-button-rounded dados-bancarios-tomador-adicao-botao",
            attrs: { label: "Adicionar nova conta" },
            on: {
              click: function ($event) {
                return _vm.exibirModalAdicionarNovaConta()
              },
            },
          }),
        ],
        1
      ),
      _c("AdicionarNovaContaDialog", {
        attrs: { visible: _vm.adicionarNovaContaDialog },
        on: {
          fecharModal: function ($event) {
            _vm.adicionarNovaContaDialog = false
          },
          salvarNovaConta: function ($event) {
            return _vm.adicionarContaBancaria()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Selecione uma conta para recebimento "),
      _c("span", [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }