var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.premio
    ? _c("div", { staticClass: "flex justify-center mt-16" }, [
        _c("div", { staticClass: "premio-card" }, [
          _vm._m(0),
          _c("div", { staticClass: "value" }, [
            _c("p", { staticClass: "title-value" }, [_vm._v("Apólice atual")]),
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.premio.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                ) +
                "\n    "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _c("i", { staticClass: "onpoint-currency-circle-dollar icon" }),
      _vm._v("\n      Prêmio Estimado\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }